@use '~/styles' as *;

.root {
  --layout-bottom-padding: var(--spacing-64);

  --navigation-menu-collapse-duration: var(--duration-slow-03);

  --navigation-menu-padding-current: 0px;
  --navigation-menu-width-current: var(--navigation-menu-width-default);

  --mobile-header-height-default: var(--mobile-header-height-small);
  --mobile-header-height-current: var(--mobile-header-height-default);

  --layout-sticky-header-top-current: var(--mobile-header-height-current);

  --collapsible-content-opacity: 1;

  display: grid;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: var(--mobile-header-height-current);
  padding-left: var(--navigation-menu-padding-current);
  transition:
    padding-left var(--navigation-menu-collapse-duration) ease-in-out,
    padding-top var(--navigation-menu-collapse-duration) ease-in-out;
}

.noMenu {
  --navigation-menu-padding-current: 0px !important;
  --navigation-menu-width-current: 0 !important;
  --mobile-header-height-current: 0 !important;
}

.collapsed {
  --navigation-menu-padding-current: 0px;
  --navigation-menu-width-current: 0;
}

.mobileHeader {
  position: fixed;
  z-index: calc(var(--z-index-menu) - 2);
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  height: var(--mobile-header-height-default);
  max-height: var(--mobile-header-height-current);
  align-items: center;
  padding-left: var(--spacing-24);
  background: var(--color-bg-surface-2);
  gap: var(--spacing-12);
  transition: max-height var(--navigation-menu-collapse-duration) ease-in-out;
}

.overlay {
  position: fixed;
  z-index: calc(var(--z-index-menu) - 1);
  height: 100dvh;
  background-color: transparent;
  inset: 0;
  transition: background-color var(--duration-medium-02) ease-in-out;
}

.hiddenOverlay {
  composes: overlay;
  pointer-events: none;
}

.open {
  .overlay {
    background-color: var(--color-neutral-container-hover);
  }
}

.menu {
  position: fixed;
  z-index: var(--z-index-menu);
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--navigation-menu-width-current);
  transition: width var(--navigation-menu-collapse-duration) ease-in-out;
}

.content {
  position: relative;
  display: grid;

  // Use grid lines instead of padding to allow breakout blocks
  grid-template-columns:
    1fr
    var(--content-grid-horizontal-padding)
    minmax(
      auto,
      min(
        var(--content-max-width),
        calc(
          100vw - var(--navigation-menu-padding-current) - var(
              --content-grid-horizontal-padding
            ) * 2
        )
      )
    )
    var(--content-grid-horizontal-padding)
    1fr;

  // Allow content to stack in repeat auto rows like in column flexbox,
  // but stretch the grid to full page height to allow blocks with height 100dvh
  grid-template-rows: var(--content-grid-gap) repeat(10, auto) 1fr var(
      --layout-bottom-padding
    );
  transition: grid-template-columns var(--navigation-menu-collapse-duration)
    ease-in-out;

  // Fill the first padding row, so other elements flow into auto rows
  &::before {
    height: 0;
    content: '';
    grid-column: 1 / -1;
    pointer-events: none;
  }
}

@media (--breakpoint-medium-768-up) {
  .root {
    --mobile-header-height-default: var(--mobile-header-height-medium);
  }
}

@media (--breakpoint-large-1280-up) {
  .root {
    --navigation-menu-padding-current: var(--navigation-menu-width-default);
    --navigation-menu-width-current: var(--navigation-menu-width-default);

    --mobile-header-height-current: 0px;

    &:has([data-sticky-tabs-variant='table']) {
      // Subtract table border, cause we already have browser window border, when scrolling.
      // We can't use calc here, cause we should have a pure px value
      // for useCustomScrollWrapper, so use SCSS variable
      --layout-sticky-header-top-current: #{-$tableBorderWidth};
    }
  }

  .collapsed {
    --navigation-menu-padding-current: var(--navigation-menu-width-collapsed);
    --navigation-menu-width-current: var(--navigation-menu-width-collapsed);
    --collapsible-content-opacity: 0;
  }

  .expanded {
    --navigation-menu-padding-current: var(--navigation-menu-width-default);
    --navigation-menu-width-current: var(--navigation-menu-width-default);
  }

  .menu::after {
    display: none;
  }
}
