@use '../abstracts/' as *;

// This styles are expected to be used inside the BaseLayout component

.limitedContainer {
  grid-column: 3 / 4;
}

.outbreakContainer {
  grid-column: 1 / -1;
}

.bottomStickyContainer {
  position: sticky;
  z-index: var(--z-index-menu);
  bottom: 0;
  grid-row: -1;
}

.fullHeightContainer {
  position: absolute;
  max-height: 100dvh;
  grid-row: 2 / -1;
  inset: 0;
}

.fillLeftoverHeightContainer {
  grid-row-end: -2;
}

.stickyLineTabs {
  position: sticky;
  z-index: var(--z-index-sticky-tabs);
  top: var(--layout-sticky-header-top-current);
  display: grid;

  // little padding for contrast in sticky position
  padding-bottom: var(--spacing-2);
  margin-bottom: calc(var(--spacing-24) - var(--spacing-2));
  background-color: var(--color-bg-page);
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  & > div {
    grid-column: 3 / 4;
  }
}

.stickyTableTabs {
  position: sticky;
  z-index: var(--z-index-sticky-tabs);
  top: var(--layout-sticky-header-top-current);
  background-color: var(--color-bg-surface-1);
}
