@use '~/styles' as *;

.targetCell {
  position: sticky;
  z-index: var(--z-index-base);
  right: 0;
  border-left: var(--border-width-1) solid var(--color-border-muted);
}

.errorCell {
  position: relative;
  border-style: solid;
  border-color: var(--color-error-soft) !important;
  border-right-width: var(--border-width-1);
  border-left-width: var(--border-width-1);

  // top horizontal border (to resolve top-bottom double borders problem)
  &::before {
    position: absolute;
    top: -1px;
    width: calc(100% + var(--border-width-1));
    height: var(--border-width-1);
    background-color: var(--color-error-soft);
    content: '';
  }
}

.errorCell:has(+ .errorCell) {
  border-right-width: 0;
}
