@use '../abstracts/' as *;

.customScrollBaseContainerSurface1 {
  @include custom-scrollbar-base(var(--color-bg-surface-1));
}

.customScrollBaseContainerPage {
  @include custom-scrollbar-base(var(--color-bg-page));
}

.customScrollHoverContainer {
  // Sticky styles won't work in a nested overflow container, so we shouldn't apply any header shift
  --layout-sticky-header-top-current: 0px;
}

.customScrollHoverContainerSurface1 {
  composes: customScrollHoverContainer;

  @include custom-scrollbar-hover('.customScrollBaseContainerSurface1');
}

.customScrollHoverContainerPage {
  composes: customScrollHoverContainer;

  @include custom-scrollbar-hover('.customScrollBaseContainerPage');
}

.customScrollStickyContainer {
  position: sticky;
  z-index: var(--z-index-sticky-scrollbar);
  bottom: 0;
}

.customScrollContainer {
  @include custom-scrollbar-base(var(--color-bg-surface-1));
  @include custom-scrollbar-hover;
}
