@use '~/styles' as *;

.root {
  --checkbox-color-default: var(--color-accent-default);
  --checkbox-color-hover: var(--color-accent-hover);
  --checkbox-color-pressed: var(--color-accent-active);

  --checkbox-color: var(--checkbox-color-default);
  --checkbox-bg-color: var(--color-neutral-on-neutral);
  --checkbox-border-color: var(--color-border-soft);

  // Needed for VisuallyHidden to work properly
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: var(--spacing-8);
  user-select: none;

  &[data-is-focus-visible='true'] {
    --checkbox-color: var(--checkbox-color-hover);
    --checkbox-border-color: var(--color-border-default);

    .border {
      outline: var(--border-width-focus) solid var(--color-focus-default);
    }
  }

  &:hover {
    --checkbox-color: var(--checkbox-color-hover);
    --checkbox-bg-color: var(--color-neutral-opaque-container-muted);
    --checkbox-border-color: var(--color-border-default);
  }

  &.pressed {
    --checkbox-color: var(--checkbox-color-pressed);
    --checkbox-bg-color: var(--color-neutral-opaque-container-default);
    --checkbox-border-color: var(--color-border-default);
  }

  &.colored {
    --checkbox-border-color: var(--checkbox-color);
  }

  &[aria-checked='true'],
  &[aria-checked='mixed'] {
    --checkbox-bg-color: var(--checkbox-color);
    --checkbox-border-color: var(--checkbox-color);

    .icon {
      scale: 1;
    }
  }

  &.disabled {
    --checkbox-bg-color: var(--color-neutral-container-soft);
    --checkbox-border-color: var(--color-neutral-container-muted);

    color: var(--color-fg-disabled);
    cursor: not-allowed;
  }
}

.border {
  display: flex;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  align-items: center;
  justify-content: center;
  border: var(--border-width-2) solid var(--checkbox-border-color);
  border-radius: var(--border-radius-4);
  background: var(--checkbox-bg-color);
  transition:
    background var(--duration-fast-01) ease-out,
    border-color var(--duration-fast-01) ease-out;
}

.icon {
  color: var(--color-static-white);
  scale: 0;
  transition: scale var(--duration-fast-01) ease-out;
}
