@use '~/styles' as *;

.pinnedFieldsContainer {
  --pinned-fields-spacing: var(--spacing-16);

  position: sticky;
  top: calc(
    var(--layout-sticky-header-top-current) + var(--pinned-fields-spacing)
  );
}

.pinnedFields {
  max-height: calc(
    100dvh - var(--layout-sticky-header-top-current) - var(
        --pinned-fields-spacing
      ) * 2
  );
}
