@use '~/styles' as *;

.root {
  --tab-highlight-background: transparent;
  --tab-highlight-height: 2px;

  display: flex;
  gap: var(--tabs-spacing);
}

.tabsScrollContainer {
  display: flex;
  width: max-content;
  gap: var(--tabs-spacing);
}

.tab {
  position: relative;
  cursor: pointer;
  outline: none;
}

.line {
  --tabs-spacing: var(--spacing-16);

  .tabsScrollContainer {
    padding-right: var(--tabs-spacing);
  }

  .tab {
    padding: var(--spacing-6) var(--spacing-4);
    border-radius: var(--border-radius-8);

    &:not(:is(:hover, .pressed, .focused, .active)) {
      color: var(--color-fg-soft);
    }

    &:hover {
      --tab-highlight-background: var(--color-neutral-container-hover);

      text-decoration: none;
    }

    &.pressed {
      --tab-highlight-background: var(--color-neutral-container-active);
    }

    &.focused {
      --tab-highlight-background: var(--color-neutral-container-hover);

      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }

    &.active {
      --tab-highlight-background: var(--color-accent-default);

      &:hover {
        --tab-highlight-background: var(--color-accent-hover);
      }

      &.pressed {
        --tab-highlight-background: var(--color-accent-active);
      }

      &.focused {
        --tab-highlight-background: var(--color-accent-hover);
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: var(--tab-highlight-height);
      background: var(--tab-highlight-background);
      content: '';
    }
  }
}

.tableTabsWrapper {
  margin: 0 calc(var(--table-border-width) * -1);

  // We need this wrapper with background to avoid content overlapping
  // through borders and rounded corners, while scrolling
  background-color: var(--color-bg-surface-1);
}

.tableTabsContainer {
  border: var(--table-border-width) solid var(--table-color-border);
  border-radius: var(--table-border-radius) var(--table-border-radius) 0 0;
}

.table {
  --tabs-spacing: var(--spacing-12);

  align-items: center;
  padding-right: var(--tabs-spacing);
  gap: var(--tabs-spacing);

  .tabsScrollContainer {
    padding: var(--tabs-spacing) var(--tabs-spacing) 0;
  }

  .tab {
    --tab-background-color: var(--color-neutral-opaque-container-soft);
    --tab-border-color: var(--tab-background-color);

    padding: calc(var(--spacing-4) - var(--border-width-1))
      calc(var(--spacing-6) - var(--border-width-1));
    border: var(--border-width-1) solid var(--tab-border-color);
    border-radius: var(--border-radius-6);
    background: var(--tab-background-color);

    &:not(:is(:hover, .pressed, .focused, .active)) {
      --tab-background-color: transparent;
    }

    &.pressed {
      --tab-border-color: var(--color-neutral-container-default);
    }

    &.focused {
      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }

    &.active {
      &:is(:hover, .pressed, .focused) {
        --tab-border-color: var(--color-neutral-container-default);
      }
    }
  }
}
