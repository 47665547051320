@use '../abstracts' as *;

// 0

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// 2px

.pl-2 {
  padding-left: var(--spacing-2) !important;
}

.py-2 {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}

.px-2 {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
}

// 4px

.p-4 {
  padding: var(--spacing-4) !important;
}

.pr-4 {
  padding-right: var(--spacing-4) !important;
}

.pb-4 {
  padding-bottom: var(--spacing-4) !important;
}

.py-4 {
  padding-top: var(--spacing-4) !important;
  padding-bottom: var(--spacing-4) !important;
}

.px-4 {
  padding-right: var(--spacing-4) !important;
  padding-left: var(--spacing-4) !important;
}

// 6px

.py-6 {
  padding-top: var(--spacing-6) !important;
  padding-bottom: var(--spacing-6) !important;
}

// 8px

.p-8 {
  padding: var(--spacing-8) !important;
}

.pb-8 {
  padding-bottom: var(--spacing-8) !important;
}

.px-8 {
  padding-right: var(--spacing-8) !important;
  padding-left: var(--spacing-8) !important;
}

// 12px

.px-12 {
  padding-right: var(--spacing-12) !important;
  padding-left: var(--spacing-12) !important;
}

.pb-12 {
  padding-bottom: var(--spacing-12) !important;
}

// 16px

.p-16 {
  padding: var(--spacing-16) !important;
}

.pb-16 {
  padding-bottom: var(--spacing-16) !important;
}

.pl-16 {
  padding-left: var(--spacing-16) !important;
}

.py-16 {
  padding-top: var(--spacing-16) !important;
  padding-bottom: var(--spacing-16) !important;
}

.px-16 {
  padding-right: var(--spacing-16) !important;
  padding-left: var(--spacing-16) !important;
}

// 20px

.py-20 {
  padding-top: var(--spacing-20) !important;
  padding-bottom: var(--spacing-20) !important;
}

// 24px

.p-24 {
  padding: var(--spacing-24) !important;
}

.py-24 {
  padding-top: var(--spacing-24) !important;
  padding-bottom: var(--spacing-24) !important;
}

.pl-24 {
  padding-left: var(--spacing-24) !important;
}

.pt-24 {
  padding-top: var(--spacing-24) !important;
}

// 96px

// TODO remove, when we rework layouts to allow center content positioning,
// now we use it to create an illusion of centering
.py-96 {
  padding-top: var(--spacing-96) !important;
  padding-bottom: var(--spacing-96) !important;
}
