@use '../abstracts' as *;

.container-inline-size {
  container-type: inline-size;
}

// Positioning

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

// Container display types

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

// Sizes

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.min-w-full {
  min-width: 100%;
}

.w-min {
  width: min-content;
}

.default-control {
  --select-min-width: 248px;

  width: var(--input-width-default);
}

.min-control {
  min-width: 112px;
}

// Content layout

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.gap-2 {
  gap: var(--spacing-2);
}

.gap-4 {
  gap: var(--spacing-4);
}

.gap-6 {
  gap: var(--spacing-6);
}

.gap-8 {
  gap: var(--spacing-8);
}

.gap-12 {
  gap: var(--spacing-12);
}

.gap-16 {
  gap: var(--spacing-16);
}

.gap-20 {
  gap: var(--spacing-20);
}

.gap-24 {
  gap: var(--spacing-24);
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

// Grid

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-subgrid {
  grid-template-columns: subgrid;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-4 {
  grid-column-start: 4;
}

// Alignment

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.self-start {
  align-self: start;
}

.self-center {
  align-self: center;
}

// align-items

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

// justify-content

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

// justify-items

.justify-items-center {
  justify-items: center;
}

.justify-items-start {
  justify-items: flex-start;
}

.justify-self-start {
  justify-self: flex-start;
}
